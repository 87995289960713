::-webkit-scrollbar {
  width: 1vw; /* reset scrollbar style */
}

::-webkit-scrollbar-track {
  background: linear-gradient(#4031D9, #8A37DC);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#4031D9, #8A37DC);
  border: 0.1vw solid #ffffff;
  border-radius: 0.5vw;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #8A37DC inset !important;
            box-shadow: 0 0 0 30px #8A37DC inset !important;
    -webkit-text-fill-color: #ffffff !important;
}
