@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(112, 157, 255, 0.8),
    hsla(242, 74%, 61%, 0.8)
  ) !important;
}

/*-----------------------------------------------------------------------------*/

.reveal {
  transform: translateY(10rem);
  opacity: 0;
  transition: 1s;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

/*-----------------------------------------------------------------------------*/

.FromTop {
  animation: FromTop 1.25s ease-in-out;
}

@keyframes FromTop {
  0% {
    transform: translateY(-500px);
  }

  100% {
    transform: translateY(0);
  }
}

.FromBottom {
  animation: FromBottom 1.25s ease-in-out;
}

@keyframes FromBottom {
  0% {
    transform: translateY(500px);
  }

  100% {
    transform: translateY(0);
  }
}

.FromLeft {
  animation: FromLeft 1.25s ease-in-out;
}

@keyframes FromLeft {
  0% {
    transform: translateX(-1500px);
  }

  100% {
    transform: translateX(0);
  }
}

.FromRight {
  animation: FromRight 1.25s ease-in-out;
}

@keyframes FromRight {
  0% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(0);
  }
}

.FromFrontLight {
  animation: FromFrontLight 1.25s ease-in-out;
}

@keyframes FromFrontLight {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.FromFrontHard {
  animation: FromFrontHard 1.25s ease-in-out;
}

@keyframes FromFrontHard {
  0% {
    transform: scale(5.1);
  }

  100% {
    transform: scale(1);
  }
}

/*-----------------------------------------------------------------------------*/

.BackFlip {
  animation: BackFlip 2.25s infinite ease-in-out;
}

@keyframes BackFlip {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}
